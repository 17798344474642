import { Stack } from '@mui/material';
import type { PageProps } from 'gatsby';
import { graphql } from 'gatsby';
import type { UkRiskWarningPageQuery } from '../../../generated/types';
import { HomepageUkTemplate } from '../../components/templates/HomepageUkTemplate';
import { RestrictedMarkdown } from '../../modules/restricted/RestrictedMarkdown';
import { RestrictedSection } from '../../modules/restricted/RestrictedSection';

type UkRiskWarningPage = PageProps<UkRiskWarningPageQuery>;

export default function UkPage({ data: { content } }: UkRiskWarningPage): React.ReactElement {
  return (
    <HomepageUkTemplate seo={content?.frontmatter?.seo}>
      <RestrictedSection>
        <Stack maxWidth="md" spacing={3.5} textAlign="left">
          {content?.rawMarkdownBody && (
            <RestrictedMarkdown>{content?.rawMarkdownBody}</RestrictedMarkdown>
          )}
        </Stack>
      </RestrictedSection>
    </HomepageUkTemplate>
  );
}

export const query = graphql`
  query UkRiskWarningPage {
    content: markdownRemark(frontmatter: { layout: { eq: "UkRiskWarning" } }) {
      id
      rawMarkdownBody
      frontmatter {
        seo {
          title
          description
          image
        }
        banner
      }
    }
  }
`;
