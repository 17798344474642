import type { ReactElement } from 'react';
import { Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
import type { PpWC } from '@noah-labs/fe-shared-ui-shared';

const StyledSection = styled(Stack)(({ theme }) => ({
  alignItems: 'center',
  height: '100%',
  padding: theme.spacing(4, 0, 8),
  position: 'relative',
  textAlign: 'center',
  width: `${theme.breakpoints.values.md}px`,
  [theme.breakpoints.down('lg')]: {
    maxWidth: `${theme.breakpoints.values.md}px`,
    padding: theme.spacing(4, 3, 8),
    width: '100%',
  },
}));

export function RestrictedSection({ children }: PpWC): ReactElement {
  return (
    <Stack alignItems="center" width="100%">
      <StyledSection>{children}</StyledSection>
    </Stack>
  );
}
