import { styled } from '@mui/material/styles';
import Markdown from 'markdown-to-jsx';

export const StyledMarkdown = styled(Markdown)(({ theme }) => ({
  a: {
    color: theme.palette.websiteBrand.primary,
  },
  h1: {
    ...theme.typography.h6,
  },
  h2: {
    ...theme.typography.bodyHeadingL,
  },
  h3: {
    ...theme.typography.bodyHeadingS,
  },
  ...theme.typography.paragraphBodyS,
}));

export function RestrictedMarkdown({ children }: { children: string }): React.ReactElement | null {
  return <StyledMarkdown>{children}</StyledMarkdown>;
}
